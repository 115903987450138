<template>
  <div class="matchs">
    <div class="matchs-header">
      <h1>{{ $t('matchs.title') }} ({{ alerts.length }})</h1>
    </div>

    <div class="matchs-transactions-list" v-for="(alert, i) in alerts" :key="i">
      <div class="header">
        <div class="img-div">
          <!-- <img class="background-image" :src="alert.pictures_attachments[0]" /> -->
        </div>
        <div class="list-status">
          <div class="live-order-buy">
            <img
              v-if="alert.response_allowed"
              src="@/assets/svg/v2/products/buyOrder.svg"
              alt="Buy order"
              width="16"
              height="16"
              class="img-buy"
            />
            <img
              v-else
              src="@/assets/svg/v2/products/sellOrder.svg"
              alt="Buy order"
              width="16"
              height="16"
              class="img-sell"
            />

            <p v-if="alert.response_allowed">{{ $t('search.buy') }}</p>
            <p v-else>{{ $t('search.sell') }}</p>
          </div>
          <div
            class="match-status match-status-current"
            v-if="order_status === 'in-progress'"
          >
            <img
              src="@/assets/svg/v2/orders/in-progress.svg"
              alt="Buy order"
              width="16"
              height="16"
            />
            <span>{{ $t('matchs.in_progress') }}</span>
          </div>

          <div class="header-bottle-infos">
            <div class="ticker">
              <span>{{ alert.ticker }}</span>
            </div>
            <button class="product-title">
              {{ alert.title }}
            </button>
          </div>
        </div>
        <div class="list-header">
          <div class="sub-header">
            <el-image
              v-for="(image, i) in alert.pictures_attachments"
              :key="i"
              :src="image"
              :initial-index="i"
              :preview-src-list="alert.pictures_attachments"
              fit="contain"
              class="picture-preview"
            />
          </div>
        </div>
      </div>

      <div v-if="alert.response_allowed" class="order">
        <div class="buy-order">
          <span class="order-title">{{ $t('matchs.buying_order') }}</span>

          <div class="qty">
            <span class="infos-label">{{
              $t('product_page.orders.buy_quantity')
            }}</span>
            <span class="infos-value">{{ alert.quantity }}</span>
          </div>
          <div class="price">
            <span class="infos-label">{{
              $t('product_page.orders.buy_price')
            }}</span>
            <span class="buy-price">{{ alert.price / 100 }} EUR</span>
          </div>
        </div>

        <img
          src="@/assets/svg/v2/orders/arrow-right.svg"
          alt="Buy order"
          width="38"
          height="38"
        />

        <div class="sell-order">
          <span class="order-title">{{ $t('matchs.selling_order') }}</span>

          <div class="qty">
            <span class="infos-label">{{
              $t('product_page.orders.sell_quantity')
            }}</span>
            <span class="infos-value">{{ alert.origin.quantity }}</span>
          </div>
          <div class="price">
            <span class="infos-label">{{
              $t('product_page.orders.sell_price')
            }}</span>
            <span class="sell-price">{{ alert.origin.price / 100 }} EUR</span>
          </div>
        </div>
      </div>

      <div v-else class="order order-seller-view">
        <div class="sell-order">
          <span class="order-title">{{
            $t('matchs.selling_order_seller_view')
          }}</span>

          <div class="qty">
            <span class="infos-label">{{
              $t('product_page.orders.sell_quantity')
            }}</span>
            <span class="infos-value">{{ alert.quantity }}</span>
          </div>
          <div class="price">
            <span class="infos-label">{{
              $t('product_page.orders.sell_price')
            }}</span>
            <span class="sell-price">{{ alert.price / 100 }} EUR</span>
          </div>
        </div>

        <img
          src="@/assets/svg/v2/orders/arrow-right.svg"
          alt="Sell order"
          width="38"
          height="38"
        />

        <div class="buy-order">
          <span class="order-title">{{
            $t('matchs.buying_order_seller_view')
          }}</span>

          <div class="qty">
            <span class="infos-label">{{
              $t('product_page.orders.buy_quantity')
            }}</span>
            <span class="infos-value">{{ alert.origin.quantity }}</span>
          </div>
          <div class="price">
            <span class="infos-label">{{
              $t('product_page.orders.buy_price')
            }}</span>
            <span class="buy-price">{{ alert.origin.price / 100 }} EUR</span>
          </div>
        </div>
      </div>

      <div class="seller-infos">
        <p v-if="alert.response_allowed">
          {{ $t('paiment_process.sold_by') }}
          <span class="seller">{{ accountTypeLabel(alert.origin.user.type) }} - {{ countryLabel(alert.origin.user.country) }}</span>
        </p>
        <p v-else>
          {{ $t('matchs.sold_by_seller_view') }} :
          <span class="seller">{{ accountTypeLabel(alert.origin.user.type) }} - {{ countryLabel(alert.origin.user.country) }}</span>
        </p>
      </div>
      <div class="order-footer" v-if="alert.response_allowed">
        <button @click="refuseOrder(alert.pretrade_uuid)">
            <img
            src="@/assets/svg/v2/matchs/close.svg"
            alt="Refuse match"
          />
          <span>{{ $t('paiment_process.refuse') }}</span>
        </button>
        <button @click="selectOrder(alert)">
          <img
            src="@/assets/svg/v2/matchs/check.svg"
            alt="Accept match"
          />
          <span>{{ $t('matchs.accept_buy') }}</span>
        </button>
      </div>
    </div>

    <p v-if="alerts.length === 0" class="empty-matchs">
      {{ $t('matchs.empty') }}
    </p>

    <CommonModal
      v-if="openRefuseDialog"
      size="small"
      :modal-title="$t('commons.refuse_match_title')"
      @close="closeModal"
    >
      <refuseDialog @close="closeModal" :match_uuid="pretrade_uuid" />
    </CommonModal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { find } from 'lodash'
import refuseDialog from '@/components/modules/dialogs/refuseDialog.vue'
import CommonModal from '@/components/commons/modal.vue'

export default {
  components: {
    refuseDialog,
    CommonModal,
  },
  data() {
    return {
      openRefuseDialog: false,
      pretrade_uuid: null,
      images: [],
    }
  },
  mounted() {
    this.loadMatchs()
  },
  computed: {
    ...mapGetters({
      alerts: 'getAlerts',
      getLists: 'getLists',
    }),
  },
  methods: {
    ...mapActions({
      getAlerts: 'getAlerts',
      product: 'product',
      getOrderActions: 'getOrder',
    }),
    refuseOrder(pretrade_uuid) {
      this.pretrade_uuid = pretrade_uuid
      this.openRefuseDialog = true
    },
    closeModal() {
      this.openRefuseDialog = false
    },
    async selectOrder(alert) {
      const order = {
        uuid: alert.order_uuid_alert,
      }

      const product = {
        isbn: alert.isbn,
      }

      this.product(product)

      await this.getOrderActions(order).then(() => {
        this.$router.push('/product/order/informations')
      })
    },
    showPictures(pictures_attachments) {
      if (this.zoom_images) {
        this.zoom_images = false
      } else {
        this.zoom_images = pictures_attachments
      }
    },
    async loadMatchs() {
      await this.getAlerts().then((alert) => {
        if (alert.length > 0) {
          this.productTitle = alert[0].title
          this.buy_price = alert[0].origin.price / 100
          this.sell_price = alert[0].price / 100
          this.fraisVente = 2.5
          this.quantity = alert[0].quantity
          this.images = alert[0].pictures_attachments
          this.ticker_maitre = alert[0].isbn
          this.ticker_sub = alert[0].ticker
        }
      })
    },
    countryLabel(country) {
      const findCountry = find(this.getLists.country, { alpha2: country })

      if (findCountry) {
        return findCountry.nom_en_gb
      }
      return 'N/A'
    },
    accountTypeLabel(type) {
      if (type === 0) {
        return this.$t('commons.individual');
      } else if (type === 1) {
        return this.$t('commons.pro')
      }
      return 'N/A'
    }
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.seller-infos,
.product-title,
.user,
.user-infos {
  display: flex;
  flex-direction: column;
}

.matchs-header,
.order,
.qty,
.sub-header,
.match-status,
.header-title,
.matchs-header-end,
.bottle-title,
.price,
.user-div,
.order-footer,
.matchs-header-end button,
.product-title,
.list-status,
.header-bottle-infos {
  display: flex;
  flex-direction: row;
}

.matchs {
  font-weight: 500;
  max-width: 700px;
  width: 100%;
  margin: 0 auto;
}

.match-status,
.header-title,
.matchs-header-end {
  align-items: center;
  button {
    border: none;
    background: transparent;
    color: #ffffff;
    align-items: center;
  }
}

.list-status {
  align-items: center;
  .header-bottle-infos {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 10px;
  }
}

.matchs-header {
  padding-top: 30px;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: space-between;
  align-items: center;
}

.matchs-transactions-list {
  display: flex;
  margin: 15px 30px;
  border-radius: 12px;
  border: 1px solid $border;
  background: #333;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background-repeat: no-repeat;
  background-size: cover;
  img {
    align-items: center;
    z-index: 5;
  }
}
.order {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  width: 100%;
  height: auto;

  span.order-title {
    font-weight: bold;
  }

  span.buy-price {
    color: #84cc16;
  }

  span.sell-price {
    color: #f43f5e;
  }
  img {
    align-self: center;
  }
}

.qty {
  padding-top: 20px;
  padding-bottom: 10px;
}
.buy-order,
.sell-order {
  margin-top: 5px;
  margin-bottom: 10px;
  border-radius: 12px;
  border: 1px solid $border;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  width: 100%;
}

.buy-order {
  margin-right: -15px;
}

.sell-order {
  background: #444444;
  margin-left: -15px;
}

.order-seller-view {
  .buy-order {
    margin-left: -15px;
    margin-right: 0px;
  }

  .sell-order {
    background: #444444;
    margin-right: -15px;
    margin-left: 0px;
  }
}

.order-footer {
  justify-content: center;
  border-top: 1px solid $grey40;
  padding-top: 15px;
  width: 100%;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 170px;
    padding: 7px 0px;
    border-radius: 52px;
    background: $blue;
    border: none;
    color: white;
    font-weight: bold;
    margin: 0 10px;

    img {
      padding: 5px;
      background: white;
      border-radius: 50%;
      width: 20px;
    }

    span {
      margin-left: 10px;
    }
  }
}

.live-order-buy {
  display: flex;
  border-radius: 50px;
  border: 1px solid $border;
  background: #ffffff;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.16);
  height: 27px;
  min-width: 65px;

  justify-content: center;
  align-items: center;

  .img-buy,
  .img-sell {
    background: #84cc16;
    border-radius: 50%;
  }

  .img-buy {
    background: #84cc16;
  }

  .img-sell {
    background: #f43f5e;
  }

  p {
    color: black;
    font-size: 15px;
    font-weight: 500;
    margin-left: 6px;
  }

  span {
    color: $grey40;
    font-size: 15px;
    font-weight: 500;
    margin-left: 2px;
  }
}
.product-title,
.sub-header {
  display: flex;
  justify-content: space-between;

  .picture-preview {
    border-radius: 6px;
    border: 1px solid $border;
    margin: 0 2px;
  }

  .picture-preview:last-child {
    margin-right: 0;
  }

  .picture-preview:first-child {
    margin-left: 0;
  }

  p {
    color: $grey40;
  }
}

.list-header {
  width: 100%;
}
.sub-header {
  margin: 10px auto;
  width: 100%;

  span {
    color: $grey40;
  }

  p {
    padding-left: 7.5px;
  }
}

.ticker-maitre {
  border-radius: 6px;
  border: 1px solid $border;
  color: $grey40;
  background: transparent;
  padding: 3px;
  margin-right: 10px;
}

.product-title {
  text-align: start;
  word-break: keep-all;
  color: white;
  font-size: 15px;
  font-weight: bold;
  border: 0;
  background: transparent;
  align-items: center;
  padding-bottom: 2px;
}

.match-status {
  padding-left: 10px;
  align-items: center;
}

.match-status-current {
  color: #add7f6;
}

.qty,
.price {
  justify-content: space-between;
}

.user-infos {
  justify-content: center;
  padding-left: 10px;
  color: $grey40;
}
.user {
  span.sold-by {
    color: $grey40;
    padding-bottom: 10px;
  }

  span.user-name {
    color: #ffffff;
  }
}

.header {
  width: 100%;
  height: 100%;
}

.img-div {
  position: relative;
  width: 100%;
  height: 100%;
}
.background-image {
  margin-top: -30px;
  margin-right: -30px;
  position: absolute;
  top: 0;
  right: 0;
  height: 146px;
  width: 20vw;
  border-top-right-radius: 12px;
  z-index: 0;
  object-fit: cover;
  -webkit-mask-image: linear-gradient(
    to left,
    rgba(0, 0, 0, 1) -25%,
    rgba(0, 0, 0, 0) 90%
  );
}

.empty-matchs {
  margin: 30px;
}

.seller-infos {
  display: flex;
  flex-direction: column;

  p {
    color: $grey40;
    padding-bottom: 10px;
  }

  span {
    color: $grey40;
    padding-bottom: 10px;
  }

  span.seller {
    color: white;
    padding-bottom: 5px;
  }
}

@media screen and (max-width: 500px) {

  .matchs-header {
    padding-left: 5px;
  }

  .matchs-transactions-list {
    margin: 10px 5px;
    padding: 10px;
  }

  .order-seller-view {

    img {
      width: 25px;
    }
  }

  .sell-order, .buy-order {
    padding: 10px;
  }

  .sell-order {
    margin-right: -8px!important;
  }
}
</style>
